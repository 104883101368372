import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatOption, MatSelect, MatSuffix } from '@angular/material/select';
import { SessionStorageService } from '@services';
import { DefaultSavedTableSettings, SavedTableSettings } from '@models';

@Component({
  selector: 'app-table-zoom',
  standalone: true,
  imports: [MatIcon, MatSelect, MatOption, MatFormField, MatSuffix],
  templateUrl: './table-zoom.component.html',
  styleUrl: './table-zoom.component.scss',
})
export class TableZoomComponent implements AfterContentInit {
  @Input() tableId: string | null = null;
  tableSavedData: SavedTableSettings = DefaultSavedTableSettings;
  zoomValues: number[] = [75, 100, 125, 150, 175];
  currentValue: number = 100;
  currentStep: number = this.zoomValues.indexOf(this.currentValue);
  countOfSteps = this.zoomValues.length - 1;

  constructor(private elementRef: ElementRef, private sessionStorageService: SessionStorageService) {}

  setSavedZoomAsDefault() {
    if (!this.tableId) return;
    this.tableSavedData = this.sessionStorageService.getItem(this.tableId) as SavedTableSettings;
    if (this.tableSavedData?.zoomSize) {
      this.currentValue = this.tableSavedData.zoomSize;
      this.currentStep = this.zoomValues.indexOf(this.currentValue);
      this.elementRef.nativeElement.style.zoom = `${this.currentValue}%`;
    }
  }

  ngAfterContentInit(): void {
    this.setSavedZoomAsDefault();
  }

  changeZoomValue(value: string, particularValue: boolean = false) {
    if (particularValue) {
      this.currentValue = parseInt(value);
      this.currentStep = this.zoomValues.indexOf(this.currentValue);
    } else {
      this.currentStep += parseInt(value);
      this.currentValue = this.zoomValues[this.currentStep];
    }
    this.elementRef.nativeElement.style.zoom = `${this.currentValue}%`;
    if (!this.tableId) return;
    this.tableSavedData = this.sessionStorageService.getItem(this.tableId) || DefaultSavedTableSettings;
    this.tableSavedData.zoomSize = this.currentValue;
    this.sessionStorageService.setItem(this.tableId, this.tableSavedData);
  }
}
