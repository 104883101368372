import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus, RoomSpaceModel } from '@models';

@Pipe({
  name: 'filterRoomsByName',
})
export class FilterRoomsByNamePipe implements PipeTransform {
  transform(items: RoomSpaceModel[] | undefined, searchText: string, showArchived = false): RoomSpaceModel[] {
    if (!items) {
      return [];
    }

    const filteredItems = showArchived
      ? items.filter(room => room.status === EntityStatus.Archived)
      : items.filter(room => room.status !== EntityStatus.Archived);

    if (!searchText) {
      return filteredItems;
    }
    searchText = searchText.toLocaleLowerCase();

    return filteredItems.filter(room => room.friendlyName.toLocaleLowerCase().includes(searchText));
  }
}
