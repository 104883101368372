<div *ngIf="device && locationId; else loading" class="test-chart-container">
  <div class="flex flex-wrap items-center justify-between" [class.my-4]="!isMobile">
    <div class="flex">
      <mat-button-toggle-group
        [value]="periodFilterValue"
        [class]="isMobile ? 'mobile-main-toggle-group mb-3' : 'main-toggle-group'"
        name="period"
        [disabled]="isLoading"
        (change)="changePeriod($event)"
        aria-label="period of time">
        <mat-button-toggle class="border-ui-green-light" [value]="PeriodFilterEnum.DAY">
          <mat-icon matIconPrefix *ngIf="periodFilterValue === PeriodFilterEnum.DAY && !isMobile">check</mat-icon>
          Day
        </mat-button-toggle>
        <mat-button-toggle class="border-ui-green-light" [value]="PeriodFilterEnum.WEEK">
          <mat-icon matIconPrefix *ngIf="periodFilterValue === PeriodFilterEnum.WEEK && !isMobile">check</mat-icon>
          Week
        </mat-button-toggle>
        <mat-button-toggle class="border-ui-green-light" [value]="PeriodFilterEnum.MONTH">
          <mat-icon matIconPrefix *ngIf="periodFilterValue === PeriodFilterEnum.MONTH && !isMobile">check</mat-icon>
          Month
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button *ngIf="!isMobile" class="btn-medium btn-yellow ml-2" [disabled]="isLoading" (click)="refreshCharts()">
        Refresh
      </button>
      <mat-spinner matSuffix *ngIf="isLoading" class="ml-2" diameter="40" color="primary"></mat-spinner>
    </div>

    <mat-form-field
      class="attribute-selector select-classic bg-white"
      [ngClass]="{ 'w-full mb-3': isMobile, 'max-w-[232px]': !isMobile }">
      <mat-select
        [value]="selectedChart"
        [disabled]="isLoading"
        (valueChange)="chartChange($event)"
        panelClass="single-select-overlay">
        <mat-select-trigger>{{ attributeNames ? attributeNames[selectedChart] : selectedChart }}</mat-select-trigger>
        <mat-option *ngFor="let chart of chartOptions" [value]="chart.value">
          <div class="flex py-2">
            <div class="attributes">
              <div>
                {{ chart.friendlyName || chart.title }}
              </div>
              <div>
                {{ chart.friendlyName ? chart.title : ' ' }}
              </div>
            </div>
            <div
              class="edit-friendly-name"
              appRestrictedEl
              [showForClientAdmin]="true"
              (click)="openEditDataPointPopup(chart)">
              <mat-icon class="m-0">edit</mat-icon>
            </div>
          </div>
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>
  </div>

  <mat-card class="card mb-4 p-3">
    <ngx-skeleton-loader *ngIf="!chartReadyToRender" [theme]="{ height: '400px' }" />
    <highcharts-chart
      *ngIf="chartData"
      [class.is-mobile]="isMobile"
      [Highcharts]="Highcharts"
      [options]="chartData"
      [update]="updateFromInput"
      [oneToOne]="true"
      (chartInstance)="logChartInstance($event)" />
    <div *ngIf="chartReadyToRender && !chartData" class="h-[400px]"></div>
  </mat-card>
</div>

<ng-template #loading>
  <div class="test-skeleton-loading">
    <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  </div>
</ng-template>
