@let zoomPercentValue = 100 + zoom * zoomPercentStep * (isMobile ? 2 : 1) + '%';
<div
  #wrapper
  [id]="documentTab === defaultDocumentTab ? 'app-spaceplan' : 'app-spaceplan' + '-' + documentTab"
  [style]="{ height: isFullScreen ? '100%' : containerHeight + 'px' }"
  [ngClass]="{
    'fixed top-0 left-0 w-full z-[1000] bg-white floor-plan-full-screen': isFullScreen,
    relative: !isFullScreen,
    'overflow-x-hidden overflow-y-auto': zoom === minZoom,
    'overflow-auto': zoom !== minZoom,
    'max-h-[80vh]': !isFullScreen,
    'cursor-grab': !isDragging,
    'cursor-grabbing': isDragging,
    'select-none': true
  }">
  <div
    class="img-wrapper relative overflow-hidden"
    [style.width]="zoomPercentValue"
    *ngIf="clientId && locationId && spaceplan.id && spaceplan.documentReference">
    <img
      alt="floor plan"
      #img
      [draggable]="false"
      [src]="outsideImgBlobUrl || imgBlobUrl"
      class="select-none pointer-events-none w-full"
      [class.h-0]="!isImgLoaded" />

    @if (spaceplan.attachments.length) {
    <ng-container
      *ngFor="
        let attachment of spaceplan.attachments
          | filterById : currentAttachmentId
          | filterAttachmentByType : documentTab : editAttachmentId
      ">
      @let isRoomAttachment = !!(attachment.type);
      <app-spaceplan-attachment
        class="pointer-events-none"
        [allowNavigation]="!currentAttachmentId"
        [editMode]="attachment.id === editAttachmentId"
        [ngStyle]="{ 'z-index': attachment.id === editAttachmentId ? 1 : 0 }"
        [attachment]="attachment"
        [containerWidth]="width"
        [containerHeight]="height"
        [popover]="popover"
        [documentTab]="documentTab"
        [routerLink]="
          isRoomAttachment
            ? ['/dashboard', locationId, spaceplan.id, attachment.id]
            : ['/devices', locationId, attachment.id]
        "
        [popoverData]="canEdit
              ? {
                  locationId,
                  attachmentName: attachment.friendlyName,
                  id: attachment.id,
                  dialogTitle: isRoomAttachment ? 'Unassign a Room' : 'Unassign a Device',
                  unstructuredDataReference: attachment.unstructuredDataReference,
                  documentTab: documentTab,
                  dialogText:  isRoomAttachment
                    ? 'Are you sure you want to unassign a room [' + attachment.friendlyName + '] from the floor plan?'
                    : 'Are you sure you want to unassign a device [' + attachment.friendlyName + '] from the block diagram?'
                }
              : {
                details: attachment,
                floorId: spaceplan.id,
                locationId: locationId,
                allowNavigation: !currentAttachmentId
              }"
        (confirmEvent)="confirm($event, isRoomAttachment)"
        (cancelEvent)="resetAttachmentId()" />
    </ng-container>
    }
  </div>
</div>
<button
  class="test-enter-fullscreen-btn btn-gray-transparent btn-medium w-10 p-0 absolute right-6 top-6 z-[1000]"
  *ngIf="!isFullScreen"
  (click)="enterFloorplanFullScreen()">
  <i class="icon-ic_zoom1 text-3xl"></i>
</button>
<button
  class="test-exit-fullscreen-btn exit-fullscreen-btn btn-gray-transparent btn-medium w-10 p-0 rounded-full fixed left-6 top-6 z-[1000]"
  *ngIf="isFullScreen"
  (click)="exitFloorplanFullScreen()">
  <i class="icon-arrow-left text-3xl"></i>
</button>

<div
  class="test-zoom-btn-wrapper zoom-btn-wrapper rounded-md flex flex-row items-center left-1/2 -translate-x-1/2 z-[1000]"
  [ngClass]="{
    'fixed': isFullScreen,
    'absolute': !isFullScreen,
    'bottom-6': isFullScreen || isMobile,
    'bottom-16': !isMobile && !isFullScreen,
  }">
  <button
    class="test-decreese-zoom-btn btn-medium flex items-center justify-center w-10 p-0 text-3xl text-white disabled:bg-transparent disabled:opacity-50"
    [disabled]="zoom === minZoom"
    (click)="zoomDecrease()">
    -
  </button>
  <span class="mx-1 font-nunito text-sm text-white"> {{ zoomPercentValue }} </span>
  <button
    class="test-increese-zoom-btn btn-medium flex items-center justify-center w-10 p-0 text-3xl text-white disabled:bg-transparent disabled:opacity-50"
    [class.btn-disabled]="zoom === maxZoom"
    [disabled]="zoom === maxZoom"
    (click)="zoomIncrease()">
    +
  </button>
</div>
<ngx-skeleton-loader *ngIf="!isImgLoaded" [theme]="{ height: '400px' }" />
<div class="flex justify-between">
  <app-delete-spaceplan
    *ngIf="isImgLoaded && !isMobile"
    [documentReference]="spaceplan.documentReference"
    [floorId]="spaceplan.parentSpaceId || spaceplan.id"
    [roomId]="spaceplan.parentSpaceId ? spaceplan.id : ''"
    [locationId]="locationId"
    class="mt-4 ml-2" />
</div>
