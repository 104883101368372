import { Component, DestroyRef, ViewChild, inject, AfterContentInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { AppService, BuildingDataFilterService } from '@services';
import { EntityStatus, IncidentStatus, LocationData, UserRolesIds } from '@models';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, areLocationsLoaded, isLocationsSummariesLoading, updateLocation, userRole } from '@ngrx-store';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { naturalSorting } from '@app-lib';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { RouterLink } from '@angular/router';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { DirectivesModule, VirtualTableScrollNormalizerDirective } from '@directives';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export const siteTableCurrentColumns = [
  'index',
  'friendlyName',
  'status',
  'address',
  'incidents',
  'deviceStatus',
  'action',
];
export const siteTableArchivedColumns = ['index', 'friendlyName', 'status', 'address', 'action'];

@Component({
  standalone: true,
  selector: 'app-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.scss'],
  imports: [
    MatSort,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    RouterLink,
    EntityStatusComponent,
    NgxSkeletonLoaderModule,
    NgIf,
    MatPaginator,
    DirectivesModule,
    NgClass,
    NoDataComponent,
    MatRow,
    MatRowDef,
    MatHeaderRow,
    AsyncPipe,
    MatHeaderRowDef,
    MatTooltip,
    MatSortHeader,
    DeviceStatusCountComponent,
    IncidentStatusCountComponent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    VirtualTableScrollNormalizerDirective,
  ],
})
export class SiteTableComponent implements AfterContentInit {
  @Input() isInjected = false;
  @Input() tableId: string | null = null;
  locationsLoaded$: Observable<boolean>;
  isLocationsSummariesLoading$: Observable<boolean>;
  entityStatus = EntityStatus;
  incidentStatus = IncidentStatus;
  dataSource: MatTableDataSource<LocationData> = new MatTableDataSource();
  displayedColumns = siteTableCurrentColumns;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  destroyRef = inject(DestroyRef);
  userRole$: Observable<UserRolesIds | undefined>;
  userRolesIds = UserRolesIds;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private appService: AppService,
    public buildingDataFilterService: BuildingDataFilterService
  ) {
    this.locationsLoaded$ = this.store.select(areLocationsLoaded);
    this.isLocationsSummariesLoading$ = this.store.select(isLocationsSummariesLoading);
    this.buildingDataFilterService.filteredLocations$.pipe(takeUntilDestroyed()).subscribe(locations => {
      this.initTableData(locations);
    });

    buildingDataFilterService.buildingStatusFilter$.pipe(takeUntilDestroyed()).subscribe(value => {
      this.displayedColumns = value === 'current' ? siteTableCurrentColumns : siteTableArchivedColumns;
    });

    this.userRole$ = this.store.select(userRole);
  }

  ngAfterContentInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  initTableData(locations: LocationData[]) {
    this.dataSource = new MatTableDataSource<LocationData>(locations);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = naturalSorting;
  }

  openConfirmationDialog({
    site,
    status,
    title,
    description,
  }: {
    site: LocationData;
    status: EntityStatus;
    title: string;
    description: string;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateLocation({
              clientId: this.appService.currentClient,
              locationId: site.id,
              data: { status },
            })
          );
        }
      });
  }

  resumeSite(site: LocationData) {
    this.openConfirmationDialog({
      site,
      status: EntityStatus.Active,
      title: 'Resume',
      description: `Are you sure you want to resume the ${site.friendlyName}?`,
    });
  }

  pauseSite(site: LocationData) {
    this.openConfirmationDialog({
      site,
      status: EntityStatus.Paused,
      title: 'Pause',
      description: `Are you sure you want to pause the ${site.friendlyName}?`,
    });
  }

  archiveSite(site: LocationData) {
    this.openConfirmationDialog({
      site,
      status: EntityStatus.Archived,
      title: 'Add to Archive',
      description: `Are you sure you want to add ${site.friendlyName} to archive?`,
    });
  }

  restoreSite(site: LocationData) {
    this.openConfirmationDialog({
      site,
      status: EntityStatus.Active,
      title: 'Restore',
      description: `Are you sure you want to restore ${site.friendlyName} from archive?`,
    });
  }
}
