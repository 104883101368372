<div>
  <label *ngIf="label" for="{{ controlName }}" class="input-main-label">{{ label }}</label>
  <form class="input-main-form" [formGroup]="form" (ngSubmit)="submitForm()">
    @if (hasPrefix) {
    <ng-content />
    }
    <mat-form-field class="input-main" [class]="formFieldClass">
      <input
        #input
        [formControlName]="controlName"
        id="{{ controlName }}"
        matInput
        placeholder="{{ placeholder }}"
        type="{{ formItemType }}"
        [min]="min"
        [max]="max"
        [shownMaskExpression]="applyPhoneMask ? phoneMaskExpression || DEFAULT_COUNTRY.maskExpression : null"
        [mask]="applyPhoneMask ? phoneMask || DEFAULT_COUNTRY.mask : null"
        autocomplete="off"
        (focus)="onFocus()"
        (blur)="onBlur()" />
      <button
        *ngIf="!form.controls[controlName].disabled && !form.controls[controlName].errors && !showSubmitButton"
        matSuffix
        class="bg-transparent font-nunito text-xs text-ui-dark font-semibold px-2 mx-3 rounded-md border border-ui-gray-light leading-[22px]">
        Edit
      </button>
      <mat-icon matSuffix *ngIf="form.controls[controlName].errors">report</mat-icon>
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
    <button
      type="submit"
      class="text-white font-nunito font-semibold bg-ui-green-light hover:bg-ui-green-350 active:bg-ui-green-light px-2 text-xs leading-6 rounded-md"
      matSuffix
      *ngIf="form.valid"
      #submitButton
      [class.is-active]="showSubmitButton">
      Save
    </button>
  </form>
</div>
