@let currentUserRole = (userRole$ | async);
<cdk-virtual-scroll-viewport
  itemSize="1"
  appVirtualTableScrollNormalizer
  [changeTrigger]="dataSource"
  [tableId]="tableId"
  class="overflow-auto"
  [ngClass]="{ 'fixed-scroll': isInjected }">
  <table
    mat-table
    matSort
    matSortActive="deviceName"
    matSortDirection="asc"
    #tableContent
    [dataSource]="dataSource"
    class="main-table"
    [ngClass]="{ 'no-actions-table': currentUserRole === userRolesIds.ReadOnly }"
    id="devices-table">
    <!-- Number Column -->
    <ng-container matColumnDef="index" [sticky]="!isMobile">
      <th mat-header-cell *matHeaderCellDef>№</th>
      <td mat-cell *matCellDef="let index = index">
        {{ index + 1 }}
      </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" [sticky]="!isMobile">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Device Name Column -->
    <ng-container matColumnDef="deviceName" [sticky]="!isMobile">
      <th
        class="table-border-last-left-sticky"
        [ngClass]="{ 'left-10': !isMobile }"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by device name">
        Device Name
      </th>
      <td
        [ngClass]="{ 'left-10': !isMobile }"
        class="table-border-last-left-sticky"
        mat-cell
        *matCellDef="let element">
        <a class="cursor-pointer" [class.underline]="!devicesFilterService.isReportMode" (click)="goToDevice(element)">
          {{ element.deviceName }}
        </a>
      </td>
    </ng-container>

    <!-- Firmware Column -->
    <ng-container matColumnDef="firmware">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by firmware">Firmware</th>
      <td mat-cell *matCellDef="let element">
        {{ element.firmware }}
      </td>
    </ng-container>

    <!-- Building Column -->
    <ng-container matColumnDef="building">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by building">Building</th>
      <td mat-cell *matCellDef="let element">
        <a
          [class.underline]="!devicesFilterService.isReportMode"
          [routerLink]="devicesFilterService.isReportMode ? null : ['/dashboard', element.buildingId]"
          >{{ element.building || '-' }}</a
        >
      </td>
    </ng-container>

    <!-- Floor Column -->
    <ng-container matColumnDef="floor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by floor">Floor</th>
      <td mat-cell *matCellDef="let element">
        @if (devicesFilterService.isReportMode || !element.floorId) {
        {{ element?.floor || '-' }}
        } @else {
        <a class="underline" [routerLink]="['/dashboard', element.buildingId, element.floorId]">{{
          element?.floor || '-'
        }}</a>
        }
      </td>
    </ng-container>

    <!-- Room Column -->
    <ng-container matColumnDef="room">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by room">Room</th>
      <td mat-cell *matCellDef="let element">
        @if (devicesFilterService.isReportMode || !element.roomId) {
        {{ element?.room || '-' }}
        } @else {
        <a class="underline" [routerLink]="['/dashboard', element.buildingId, element.floorId, element.roomId]">{{
          element?.room || '-'
        }}</a>
        }
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Description</th>
      <td mat-cell *matCellDef="let element" [class.is-offline]="element.status === EntityStatus.Paused">
        Some description will be added here
      </td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Device Status</th>
      <td mat-cell *matCellDef="let element" [class.is-offline]="element.status === EntityStatus.Paused">
        <app-entity-status [status]="element.status" />
      </td>
    </ng-container>

    <!-- Device Type Column -->
    <ng-container matColumnDef="deviceType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by device type">Device Type</th>
      <td mat-cell *matCellDef="let element">{{ element.deviceType || '-' }}</td>
    </ng-container>

    <!-- Manufacturer Column -->
    <ng-container matColumnDef="manufacturer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by manufacturer">
        Manufacturer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.manufacturer || '-' }}</td>
    </ng-container>

    <!-- Model Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by model">Model</th>
      <td mat-cell *matCellDef="let element">{{ element.model }}</td>
    </ng-container>

    <!-- Serial Number Column -->
    <ng-container matColumnDef="serialNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by serial number">Serial №</th>
      <td mat-cell *matCellDef="let element">{{ element.serialNumber || '-' }}</td>
    </ng-container>

    <!-- IP address Column -->
    <ng-container matColumnDef="ipAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IP address">IP address</th>
      <td mat-cell *matCellDef="let element">{{ element.ipAddress || '-' }}</td>
    </ng-container>

    <!-- Incident Column -->
    <ng-container matColumnDef="incidents">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [hidden]="devicesFilterService.deviceStatusFilter === 'archived'">
        Incidents
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="devicesFilterService.deviceStatusFilter === 'archived'">
        <ng-container *ngIf="element.status === entityStatus.Active">
          <app-incident-status-count
            *ngIf="element.incidentCountByStatuses.newCount"
            [status]="incidentStatus.IN_QUEUE"
            [amount]="element.incidentCountByStatuses.newCount"
            class="mr-1" />
          <app-incident-status-count
            *ngIf="element.incidentCountByStatuses.inProgressCount"
            [status]="incidentStatus.IN_PROGRESS"
            [amount]="element.incidentCountByStatuses.inProgressCount" />
        </ng-container>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <!--    [stickyEnd]="!isMobile"-->
    <ng-container matColumnDef="actions" [stickyEnd]="!isMobile">
      <th
        mat-header-cell
        class="table-border-last-right-sticky"
        *matHeaderCellDef
        appRestrictedEl
        [showForClientAdmin]="true"
        [class.hidden]="
          devicesFilterService.deviceStatusFilter === 'archived' && currentUserRole === userRolesIds.ClientAdmin
        "></th>
      <td
        mat-cell
        class="table-border-last-right-sticky"
        *matCellDef="let element"
        appRestrictedEl
        [showForClientAdmin]="true"
        [class.hidden]="
          devicesFilterService.deviceStatusFilter === 'archived' && currentUserRole === userRolesIds.ClientAdmin
        ">
        <div class="flex justify-end">
          <i
            *ngIf="element.status !== deviceStatuses.Archived"
            appRestrictedEl
            [routerLink]="['/devices', 'edit', element.buildingId, element.id]"
            matTooltip="Edit Device"
            class="cursor-pointer text-ui-gray-100 text-3xl icon-edit"></i>
          <i
            class="icon-play-16 action-icon"
            appRestrictedEl
            [showForClientAdmin]="true"
            [matTooltip]="'Resume'"
            (click)="resumeDevice(element)"
            *ngIf="element.status === EntityStatus.Paused"></i>
          <i
            class="icon-pause-16 action-icon"
            [matTooltip]="'Pause Device'"
            appRestrictedEl
            [showForClientAdmin]="true"
            (click)="pauseDevice(element)"
            *ngIf="element.status === EntityStatus.Active"></i>

          <i
            matTooltip="Archive"
            appRestrictedEl
            *ngIf="element.status !== deviceStatuses.Archived"
            (click)="archiveDevice(element)"
            class="icon-add-to-archive action-icon"></i>

          <i
            appRestrictedEl
            (click)="restoreDevice(element)"
            matTooltip="Restore"
            *ngIf="element.status === deviceStatuses.Archived"
            class="icon-restore action-icon"></i>

          <i
            class="icon-remove action-icon text-4xl"
            appRestrictedEl
            (click)="deleteDevicePermanently(element.id)"
            matTooltip="Delete Device"
            *ngIf="element.status === deviceStatuses.Archived"></i>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="devicesFilterService.displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: devicesFilterService.displayedColumns"></tr>
  </table>
</cdk-virtual-scroll-viewport>
<app-no-data
  *ngIf="!(dataSource.filteredData && dataSource.filteredData.length)"
  [resultContent]="devicesFilterService.filtersApplied()"
  noDataText="No devices yet"
  [hasBigPadding]="isNoDataBigSize" />
