import { Injectable } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;

  constructor(private router: Router) {}

  public init(): Observable<void> {
    return new Observable(observer => {
      this.currentUrl = this.router.url;
      this.createRouterEventsObservable().subscribe();
      observer.next();
      observer.complete();
    });
  }

  private createRouterEventsObservable(): Observable<any> {
    return this.router.events.pipe(
      tap((event: NavigationEvent) => {
        if (event instanceof NavigationEnd) {
          if (this.currentUrl === '/') {
            this.currentUrl = event.url;
          }
          this.updateUrls(event.url);
        }
      })
    );
  }

  private updateUrls(url: string): void {
    this.previousUrl = this.removeQueryString(this.currentUrl);
    this.currentUrl = this.removeQueryString(url);
  }

  private removeQueryString(input: string = ''): string {
    const index = input.indexOf('?');
    if (index !== -1) {
      return input.substring(0, index);
    }
    return input;
  }

  public getPreviousUrl(): string | undefined {
    return this.previousUrl;
  }
}
