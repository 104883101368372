import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus, FloorSpaceModel } from '@models';

@Pipe({
  name: 'filterFloorSpaceByName',
})
export class FilterFloorSpaceByNamePipe implements PipeTransform {
  transform(items: FloorSpaceModel[], searchText: string, showArchived = false): FloorSpaceModel[] {
    if (!items) {
      return [];
    }

    let filteredItems;
    if (showArchived) {
      filteredItems = items.filter(floor => {
        if (floor.status === EntityStatus.Archived) {
          return true;
        }
        const floorHasArchivedRoom = floor.rooms?.filter(room => room.status === EntityStatus.Archived);
        return floorHasArchivedRoom?.length || false;
      });
    } else {
      filteredItems = items.filter(floor => floor.status !== EntityStatus.Archived);
    }

    if (!searchText) {
      return filteredItems;
    }
    searchText = searchText.toLocaleLowerCase();
    return filteredItems.filter(floor => floor.friendlyName.toLocaleLowerCase().includes(searchText));
  }
}
